<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Program Owner Handover Approval</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <place-holder v-if="loading"></place-holder>
                        <div class="table-responsive">
                            <table class="table table-striped dt-responsive w-100 mb-3" id="handover-list-datatable"
                                v-show="!loading">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Issued Date</th>
                                        <th>Program Name</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Handover Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(handoverApproval, index) in filterApprovalLists" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td>{{ handoverApproval.issued_date }}</td>
                                        <td>{{ handoverApproval.program_name }}</td>
                                        <td>{{ handoverApproval.trainer_from.name }}</td>
                                        <td>{{ handoverApproval.trainer_to.name }}</td>
                                        <td>
                                            <span v-if="handoverApproval.status == 'Requested'" class="text-warning">{{
                                                handoverApproval.status }} by
                                                {{ handoverApproval.requested_by.name }}
                                            </span>
                                            <span v-if="handoverApproval.status == 'Approved'" class="text-success">{{
                                                handoverApproval.status }} by
                                                {{ handoverApproval.changed_status_by.name }}
                                            </span>
                                            <span v-if="handoverApproval.status == 'Rejected'" class="text-danger">{{
                                                handoverApproval.status }} by
                                                {{ handoverApproval.changed_status_by.name }}</span>
                                        </td>
                                        <td v-if="hasPermissions(['module-group-edit'])">
                                            <router-link :to="{
                                                name: 'program-handover-approval-update',
                                                params: { id: handoverApproval.id },
                                            }">
                                                <span>
                                                    <i class="fas fa-eye"></i>
                                                    View/Edit
                                                </span>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//Datatable handoverApproval
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            approvalLists: [],
            filterApprovalLists: [],
            loading: false,
            current_user_id: this.$store.state.auth.user.employee_id,
            baseUrl: process.env.VUE_APP_BASE_URL,
        };
    },
    methods: {
        async getAllRequestedHandoverLists() {
            this.loading = true;
            this.$Progress.start();
            await axios
                .get(`${this.baseUrl}admin/v1/requested-program-handover-lists`)
                .then((response) => {
                    this.approvalLists = response.data.data;
                    if (
                        this.$store.state.auth.user.roles[0] == "Training Head" || this.$store.state.auth.user.roles[0] == "Super Admin") {
                        this.filterApprovalLists = this.approvalLists;
                    } else {
                        this.approvalLists.map((item) => {
                            if (item.trainer_to.employee_id == this.current_user_id) {
                                this.filterApprovalLists.push(item);
                            }
                        })
                    }
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                });
            $("#handover-list-datatable").DataTable();
        },
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#handover-list-datatable").DataTable().draw();
        },
    },
    created() {
        this.clearFilter();
        this.getAllRequestedHandoverLists();
    },
};
</script>

<style>
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
}

a {
    color: #4a81d4;
    text-decoration: none;
}
</style>
  